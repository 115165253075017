const state = {
  webData: '' // 网站基础数据
}
const getters = {
  getCatname: (state) => (id) => { // 根据ID获取分类名称
    const cat = state.webData.cates.find(item => item.id === id)
    if (cat) {
      return cat.catname
    }
  },
  getInfo: (state) => (id) => { // 根据ID获取1条信息
    const info = state.webData.infos.find(item => item.id === id)
    if (info) {
      return info
    }
  },
  getCatInfos: (state) => (catid) => { // 根据ID获取类目信息列表
    const info = state.webData.infos.filter(item => item.cateid === catid)
    if (info) {
      return info
    }
  },
  getTopInfos: (state) => { // 获取置顶信息列表
    return state.webData.infos.filter(item => item.top === 1)
  },
  getMember: (state) => (id) => { // 根据ID获取1条用户信息
    return state.webData.members.find(item => item.id === id)
  },
  getFile: (state) => (id) => { // 根据ID获取1条文件信息
    return state.webData.files.find(item => item.id === id)
  }
}
const mutations = {
  setWebData (state, payload) {
    state.webData = payload
  }
}
const store = {
  namespaced: true,
  state,
  getters,
  mutations
}
export default store
